import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Col, Row, Card, Form } from 'react-bootstrap';
import Button from '@material-ui/core/Button';

import { useQuery } from '@apollo/client';
import { GET_All_SUBMITTED_DATA } from './../queries';

import CompanyDetails from './overview/companyDetails';
import Webdesign from './overview/webdesign';
import ExtraModules from './overview/extraModules';
import NavBarDemoComponent from '../components/Demos/NavBarDemoComponent';
import ProductDemoComponent from '../components/Demos/ProductDemoComponent';
import FontDemoComponent from '../components/Demos/FontDemoComponent';
import HomePhotoDemoComponent from '../components/Demos/HomePhotoDemoComponent';

function Overview(props) {

  const [data, setData] = useState([]);

  const { loading: loadingUser, error: errorUser, data: dataUser } =
    useQuery(GET_All_SUBMITTED_DATA, {
      variables: {
        domain_name: localStorage.getItem('domain_name'),
        user_id: localStorage.getItem('user_id')
      },
      skip: !localStorage.hasOwnProperty('domain_name') || !localStorage.hasOwnProperty('user_id')
    });

  useEffect(() => {
    if (data.length === 0) {
      if (!loadingUser && !errorUser && dataUser) {
        if (dataUser.siteUserOverview) {
          console.log(dataUser.siteUserOverview);
          setData(dataUser.siteUserOverview);
        }
      }
    }
  }, [data, loadingUser, errorUser, dataUser])

  return (
    <div className="container home"
      style={{
        fontFamily: `${props.font_family}`,
        color: `${props.primary_color_2}`,
      }}>
      <Card className="text-align-left">
        <Card.Header>
          <Row>
            <div className="padding-bottom-3r">
              <Col
                md={12}
                className="no-padding"
                style={{
                  borderImage: `url(${window.location.origin + "/button_wizard_2@2x.png"}) 30% round`,
                  borderBottom: "6px solid transparent"
                }}
              >
                <h2 className="home-title black-text" >
                  {props.t('overview:Overview')}
                </h2>
              </Col>
            </div>
            <Col md={12}>
              <Row>
                <p className="col-6 black-text">
                Alles ingevuld met een tevreden gevoel? Controleer middels onderstaand overzicht of de gegevens en het uiterlijk van uw website klopt, alvorens u deze genereert.
                </p>
                <p className="col-6 black-text">
                Specifieke wensen kunnen in een later stadium op maat gestroomlijnd/ geautomatiseerd worden. Wij zijn namelijk pas tevreden als u dat bent.
                </p>
              </Row>
            </Col>
          </Row>
        </Card.Header>
        {data.length !== 0 &&
          <Card.Body>
            <Row>
              <Col md={12}>
                <Row>
                  <Col md={6}>
                    <h4 className="font-bold black-text">Bedrijfsgegevens</h4>
                    <p>
                      <CompanyDetails data={data} />
                    </p>
                    <h4 className="font-bold black-text">Webdesign</h4>
                    <p>
                      <Webdesign data={data} />
                    </p>
                    <h4 className="font-bold black-text">Extra modules</h4>
                    <p>
                      <ExtraModules data={data} />
                    </p>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} className="padding-2r-sides" style={{
                    backgroundColor: `${data.theme.background_color_6} `
                  }}>
                    <h4 className="font-bold black-text">{props.t('webdesign:Example webpage')}</h4>
                    <Row className="center-alignment bottom-card text-align-center">
                      <NavBarDemoComponent font_family={data.theme.font_family} background_color_1={data.theme.background_color_1} primary_color_2={data.theme.primary_color_2} />
                    </Row>
                    <Row className="center-alignment bottom-card">
                      <FontDemoComponent font_family={data.theme.font_family} primary_color_2={data.theme.primary_color_2} />
                    </Row>
                    <Row className="center-alignment bottom-card">
                    <HomePhotoDemoComponent font_family={data.theme.font_family} primary_color_2={data.theme.primary_color_2} primary_color_4={data.theme.primary_color_4} background_color_1={data.theme.background_color_1} background_color_2={data.theme.background_color_2} background_color_5={data.theme.background_color_5} border_radius={data.theme.border_radius} border_radius_2={data.theme.border_radius_2} />
                    </Row>
                    <Row className="center-alignment bottom-card">
                    <ProductDemoComponent border_radius={data.theme.border_radius} background_color_2={data.theme.background_color_2} background_color_3={data.theme.background_color_3} background_color_4={data.theme.background_color_4} background_color_5={data.theme.background_color_5} primary_color_3={data.theme.primary_color_3} primary_color_4={data.theme.primary_color_4} primary_color_5={data.theme.primary_color_5} font_family={data.theme.font_family} />
                    </Row>
                    <Row className="center-alignment bottom-card">
                      <div>
                        <Button
                          style={{
                            fontFamily: `${data.theme.font_family} `,
                            color: `${data.theme.primary_color_4} `,
                            backgroundColor: `${data.theme.background_color_2} `,
                            borderColor: `${data.theme.background_color_2} `,
                            borderRadius: `${data.theme.border_radius}px`,
                            width: `-webkit-fill-available`
                          }}
                          variant="contained"
                          className="btn btn-secondary"
                        >
                          <p style={{ fontFamily: `${data.theme.font_family} `, color: `${data.theme.primary_color_4}` }} className="modules-button-text">{props.t('webdesign:Example')}</p>
                        </Button>
                      </div>
                    </Row>
                  </Col>
                </Row>

              </Col>
            </Row>
            <Row className="top-page">
              <Col xs={12} sm={12} md={12} lg={12} xl={12} className="top-page bottom-card">
                <Form.Check
                  className="black-text"
                  type="checkbox"
                  // id={form.agree}
                  name="agree"
                  // checked={form.agree}
                  // onChange={(e) => handleChange(e)}
                  label={
                    <p>{props.t('overview:Agree')}<b className="font-bold">{props.t('overview:Terms')}</b>, <b className="font-bold">{props.t('overview:Privacy statement')}</b>{props.t('overview:And the')}<b className="font-bold">{props.t('overview:Extra costs')}</b></p>
                  }
                  required
                >
                </Form.Check>
                <Button
                  type="submit"
                  name="submit"
                  className="nav-button"
                  style={{
                    backgroundImage: `url(${window.location.origin + "/button_wizard_2_dh@2x.png"})`,
                    backgroundSize: 'cover !important',
                    width: 'auto'
                  }}
                >
                  <p className="modules-button-text black-text">{props.t('overview:Create my website')}</p>
                </Button>
              </Col>
            </Row>
          </Card.Body>
        }
      </Card>
    </div>
  )
}
export default withTranslation(['message', 'overview'])(Overview);