import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";

import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { useQuery, useMutation } from "@apollo/client";
import { CREATE_USER, GET_SITE_CONFIG_TYPES } from "./../queries";
import { formContent } from "../statics/RegisterFormData";
import { RegistrationSchema } from "../validations/UserValidation";

function Register(props) {
  const [login, setLogin] = useState({
    email: "",
    email_validation: "",
    password: "",
    password_validation: "",
    firstname: "",
    middlename: "",
    lastname: "",
    name: "",
    address: "",
    zipcode: "",
    experience: "",
    photographer_type: "",
    photography_type: "",
  });

  const [messages, setMessages] = useState({
    password: undefined,
    password_validation: undefined,
    email: undefined,
    email_validation: undefined,
  });
  const [validation, setValidation] = useState({
    email: undefined,
    email_validation: undefined,
    password: undefined,
    password_validation: undefined,
    agree_with_terms: undefined,
  });
  const [validated, setValidated] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [translated] = useState({
    errorMail: props.t("register:ErrorEmail"),
    AlreadyExist: props.t("register:AlreadyExist"),
    Errors: props.t("register:Error"),
    AlphaReg: props.t("register:AlphaReg"),
    AlphaUpReg: props.t("register:AlphaUpReg"),
    NumbReg: props.t("register:NumbReg"),
    TokenReg: props.t("register:TokenReg"),
    LengthReg: props.t("register:LengthReg"),
    ValidateErrorPassword: props.t("register:Validate Error Password"),
    ValidateErrorEmail: props.t("register:Validate Error Email"),
    emptyFields: <strong>{props.t("register:EmptyField")}</strong>,
  });

  const {
    register: registerYup,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onBlur",
    resolver: yupResolver(RegistrationSchema),
  });

  const setLocals = (data) => {
    localStorage.setItem("username", data.register.email);
    localStorage.setItem("user_id", data.register.id);
  }

  const {
    loading: loadingSiteConfigType,
    error: errorSiteConfigType,
    data: dataSiteConfigType,
  } = useQuery(GET_SITE_CONFIG_TYPES);

  const [
    register,
    { loading: loadingCreateAccount },
  ] = useMutation(CREATE_USER, {
    onCompleted: (data) => {
      console.log(data);
      if (data) {
        setValidated(true);
        props.nextScreen2(true);
        return (
          setLocals(data)
        )
      }
    },
    onError: (error) => {
      let err = <strong>{translated.AlreadyExist}</strong>;
      setMessages((prevState) => ({ ...prevState, email: err }));
      setValidation((prevState) => ({ ...prevState, email: false }));
      console.log(`${error}`);
    },
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const Register = (e) => {
    if (!loadingCreateAccount) {
      register({
        variables: {
          name: e.name,
          firstname: e.firstname,
          middlename: e.middlename,
          lastname: e.lastname,
          email: e.email,
          password: e.password,
        },
      });
      let object = { type: e.photographer_type, name: e.name };
      props.sendToNextScreen(object);

      /* What to do with this data? */
      // address: login.address,
      // zipcode: login.zipcode,
      // experience: login.experience,
      // photography_type: login.photography_type,
    }
  };

  return (
    <div className="container register-container">
      <Card className="card-container">
        <Card.Header>
          <Row>
            <div className="padding-bottom-3r">
              <Col
                md={12}
                className="no-padding"
                style={{
                  borderImage: `url(${
                    window.location.origin + "/button_wizard_2@2x.png"
                  }) 30% round`,
                  borderBottom: "6px solid transparent",
                }}
              >
                <h2 className="home-title">
                  {props.t("register:Company details")}
                </h2>
              </Col>
            </div>
            <Col md={12}>
              <Row>
                <p className="col-6 black-text">
                  Om een beter beeld te krijgen van wie u bent als fotograaf en
                  waar u momenteel in de markt staat met uw (eventuele) bedrijf,
                  kunt u middels onderstaand formulier een account voor het
                  online bestelsysteem van FotoProTools aanmaken.
                </p>
                <p className="col-6 black-text">
                  Daarnaast kunt u uw bedrijfsgegevens invullen, het type
                  fotografie waarin u gespecialiseerd bent, evenals het aantal
                  jaren dat u actief bent in de branche. Zo krijgen wij een
                  beter beeld van wie u bent, en op welke fotografie gebied
                  FotoProTools u het best kan bijstaan.
                </p>
              </Row>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <form
            noValidate
            validated={validated}
            onSubmit={handleSubmit(Register)}
          >
            <Row>
              {formContent.inputs.map((input, key) => {
                const agree_with_terms_label = `${props.t(
                  "register:agree"
                )} algemene voorwaarden${props.t(
                  "register:and"
                )} privacy statement`;
                const lg =
                  key <= 2
                    ? 4
                    : input.id === "agree_with_terms"
                    ? 12
                    : key >= 7
                    ? 4
                    : 6;

                return input.type !== "checkbox" ? (
                  <>
                    {(key === 0 || key === 3 || key === 7) && (
                      <Col lg={12} md={12} sm={12}>
                        <p className="black-text">
                          {(key === 0 && props.t("register:Your name")) ||
                            (key === 3 && props.t("register:Credentials")) ||
                            (key === 7 && props.t("register:Company details"))}
                        </p>
                      </Col>
                    )}
                    <Col key={key} lg={lg} md={12} sm={12} className={`mb-2`}>
                      <TextField
                        {...registerYup(input.name, { shouldUnregister: true })}
                        id={input.id}
                        autoFocus={key === 0}
                        type={
                          input.name === "password"
                            ? showPassword
                              ? "text"
                              : input.type
                            : input.type
                        }
                        label={props.t(`register:${input.label}`)}
                        autoComplete={input.autocomplete}
                        className="mb-2"
                        variant="outlined"
                        fullWidth
                        max={input.max}
                        min={input.min}
                        value={input.type === "select" ? login[input.id] : null}
                        InputProps={{
                          maxLength: input.max,
                          minLength: input.min,
                          endAdornment:
                            input.id === "password" ? (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ) : null,
                        }}
                        InputLabelProps={{
                          shrink:
                            input.type === "select"
                              ? login[input.id] !== undefined
                              : undefined,
                        }}
                        select={input.type === "select"}
                        SelectProps={
                          input.type === "select" && {
                            native: true,
                          }
                        }
                        onChange={(event) =>
                          input.id === "photographer_type" &&
                          setLogin((prevState) => ({
                            ...prevState,
                            [input.id]: event.currentTarget.value,
                          }))
                        }
                        helperText={
                          errors[input.name]
                            ? props.t(`register:${errors[input.name].message}`)
                            : messages[input.name] !== undefined ?
                              messages[input.name]
                            : validation[input.name] === false
                            ? props.t(`register:EmptyField`)
                            : input.helperText &&
                              props.t(`register:${input.helperText}`)
                        }
                        error={
                          errors[input.name] ||
                          validation[input.name] === false ||
                          messages[input.name] !== undefined
                        }
                        required={input.required}
                      >
                        {input.type === "select" && loadingSiteConfigType ? (
                          <LinearProgress
                            className="align-self-center"
                            style={{ color: props.primary_color_2 }}
                          />
                        ) : errorSiteConfigType ? null : input.type ===
                          "select" ? (
                          <>
                            <option value={"undefined"}>{props.label}</option>
                            {input.id === "photographer_type" &&
                              dataSiteConfigType &&
                              dataSiteConfigType.__type.enumValues.filter(f => f.name !== 'wizard').map(
                                (option, index) => {
                                  return (
                                    <option key={index} value={option.name}>
                                      {props.t(
                                        `register:${option.name.replace(
                                          "_",
                                          " "
                                        )}`
                                      )}
                                    </option>
                                  );
                                }
                              )}
                          </>
                        ) : null}
                      </TextField>
                    </Col>
                  </>
                ) : (
                  <Col key={key} lg={lg} md={12} col={12} className={`mb-2`}>
                    <FormControl
                      required={input.required}
                      error={errors[input.name]}
                      component="fieldset"
                    >
                      <FormControlLabel
                        {...registerYup(input.name, { shouldUnregister: true })}
                        id={input.id}
                        value={validation.agree_with_terms}
                        control={
                          <Checkbox
                            color="primary"
                            name={input.name}
                            onChange={(event) =>
                              input.id === "agree_with_terms" &&
                              setValidation((prevState) => ({
                                ...prevState,
                                agree_with_terms: event.target.checked,
                              }))
                            }
                          />
                        }
                        label={
                          input.id === "agree_with_terms"
                            ? agree_with_terms_label
                            : props.t(`register:${input.label}`)
                        }
                        labelPlacement="end"
                        className="mb-2"
                      />
                      {errors[input.name] && (
                        <FormHelperText>
                          <Alert severity="warning">
                            {props.t(`register:${errors[input.name].message}`)}
                          </Alert>
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Col>
                );
              })}
              <Col>
                <Button
                  id="submit"
                  type="submit"
                  name="submit"
                  className="nav-button home-button"
                  style={{
                    backgroundImage: `url(${
                      window.location.origin + "/button_wizard_2_dh@2x.png"
                    })`,
                    backgroundSize: "cover",
                  }}
                >
                  <p className="home-button-text">
                    {props.t("register:Register")}
                  </p>
                </Button>
              </Col>
            </Row>
          </form>
        </Card.Body>
      </Card>
    </div>
  );
}

export default withTranslation(["message", "register"])(Register);
