import React from 'react';

import { withTranslation } from 'react-i18next';
import {Button, Grid} from '@material-ui/core';

/* HOME PAGE*/

function Home(props) {

const activeExist = localStorage.hasOwnProperty('active');

    return (

            <Grid container direction="row" 
            className="home-container padding-around-home-container" 
            style={{
                fontFamily: `${props.font_family}`,
                color: `${props.primary_color_2}`,
            }}>
                <Grid item xs={12} sm={12} md={6} xl={6}>
                    <Grid container direction="row" className="padding-bottom-3r">
                        <Grid item md={6}
                            className="no-padding"
                            style={{
                                borderImage: `url(${window.location.origin + "/divider@2x.png"}) 30% round`,
                                borderBottom: "6px solid transparent"
                            }}
                        >
                            <h2 className="home-title">
                                {props.t('Welcome')},
                            </h2>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" className="home-text padding-bottom-3r" spacing={1}>
                        <Grid item xs={10} sm={10} md={6} xl={6}>
                            <p>    
                                Middels deze setup wizard kunt u een gratis demo aanvragen voor een persoonlijke website en/of een volledig geautomatiseerd online bestelsysteem, waarmee u gemakkelijk uw school- of vakfoto’s presenteert en verkoopt.
                            </p>
                            <p>
                                Wij vinden het leuk om met onze (toekomstige) klanten mee te denken, om zo online nog meer foto’s te kunnen verkopen.
                            </p>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={1}>
                        <Button
                            className="nav-button home-button"
                            style={{ backgroundImage: `url(${window.location.origin + "/button_wizard@2x.png"})`, backgroundSize: 'cover', }}
                            onClick={() => props.nextScreen(true)}>
                            <p className="home-button-text">{props.t('Start')}</p>
                        </Button>
                    </Grid>
                    {activeExist &&
                        <Grid container direction="row" spacing={1}>
                            <Button onClick={() => props.goBackToLastScreen()} 
                                className="nav-button home-button" 
                            >
                                <p className="home-button-text">{props.t('Go back')}</p>
                            </Button>
                        </Grid>
                    }
                    <Grid container direction="row" className="home-sub-text">
                        <Grid item xs={10} sm={10} md={6} xl={6}>
                            <a target="_parent" href='https://fotoprotools.nl/?page_id=106'>{props.t('No Customer')}</a>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={10} sm={10} md={6} xl={6} className="second-col">
                    <img
                        src={window.location.origin + "/logootje.png"}
                        className="homepage-brand-logo"
                        alt={props.domain_name}
                    />
                </Grid>
            </Grid>
    );
}

export default withTranslation('home')(Home);
