import React from 'react';
import { Button } from "react-bootstrap";
import { withTranslation } from 'react-i18next';

class RouteComponent extends React.Component {


  onNavigateHome() {
    window.location.replace(`/`);
  }

  render() {
    return (
      <div className="container CartRoute"
        style={{
          fontFamily: `${this.props.font_family}`,
          color: `${this.props.color2}`
        }} >
        {this.props.active5 ?
          <div className="hide-btns">
            <Button
              style={{ backgroundImage: `url(${window.location.origin + "/button_wizard@2x.png"})`, backgroundSize: 'cover', }}
              onClick={this.onNavigateHome}
              className="nav-button home-button">
              <p className="home-button-text">{this.props.t('Back to Home')}</p>
            </Button>
            <Button
              // href='/register'
              onClick={() => this.props.setActive({ active1: false, active2: true, active3: false, active4: false, active5: false })}
              style={{ backgroundColor: `transparent`, borderColor: `transparent` }}
              className="btn btn-light align-button btn-arrow-right">
              <img src={`${window.location.origin + "/progress@2x.png"}`} alt="" style={{ maxHeight: "8vh" }} />
            </Button>
            <Button
              // href='/web-design'
              onClick={() => this.props.setActive({ active1: false, active2: false, active3: true, active4: false, active5: false })}
              style={{ backgroundColor: `transparent`, borderColor: `transparent` }}
              className="btn btn-light align-button btn-arrow-right">
              <img src={`${window.location.origin + "/wizardprogress1@2x.png"}`} alt="" style={{ maxHeight: "8vh" }} />
              {/* {this.props.t('Web design')} */}
            </Button>
            <Button
              // href='/modules'
              onClick={() => this.props.setActive({ active1: false, active2: false, active3: false, active4: true, active5: false })}
              style={{ backgroundColor: `transparent`, borderColor: `transparent` }}
              className="btn btn-light align-button btn-arrow-right">
              <img src={`${window.location.origin + "/wizardprogress2@2x.png"}`} alt="" style={{ maxHeight: "8vh" }} />
              {/* {this.props.t('Extra')} */}
            </Button>
            <Button
              style={{ backgroundColor: `transparent`, borderColor: `transparent` }}
              className="btn btn-light align-button btn-arrow-right">
              <img src={`${window.location.origin + "/wizardprogress3@2x.png"}`} alt="" style={{ maxHeight: "8vh" }} />
              {/* {this.props.t('Overview')} */}
            </Button>
          </div>
          : this.props.active4 ? // modules
            <div className="hide-btns">
              <Button
                style={{ backgroundImage: `url(${window.location.origin + "/button_wizard@2x.png"})`, backgroundSize: 'cover', }}
                onClick={this.onNavigateHome}
                className="nav-button home-button">
                <p className="home-button-text">{this.props.t('Back to Home')}</p>
              </Button>
              <Button
                // href='/register'
                onClick={() => this.props.setActive({ active1: false, active2: true, active3: false, active4: false, active5: false })}
                style={{ backgroundColor: `transparent`, borderColor: `transparent` }}
                className="btn btn-light align-button btn-arrow-right">
                <img src={`${window.location.origin + "/progress@2x.png"}`} alt="" style={{ maxHeight: "8vh" }} />
              </Button>
              <Button
                // href='/web-design'
                onClick={() => this.props.setActive({ active1: false, active2: false, active3: true, active4: false, active5: false })}
                style={{ backgroundColor: `transparent`, borderColor: `transparent` }}
                className="btn btn-light align-button btn-arrow-right">
                <img src={`${window.location.origin + "/wizardprogress1@2x.png"}`} alt="" style={{ maxHeight: "8vh" }} />
                {/* {this.props.t('Web design')} */}
              </Button>
              <Button
                // href='/modules'
                onClick={() => this.props.setActive({ active1: false, active2: false, active3: false, active4: true, active5: false })}
                style={{ backgroundColor: `transparent`, borderColor: `transparent` }}
                className="btn btn-light align-button btn-arrow-right">
                <img src={`${window.location.origin + "/wizardprogress2@2x.png"}`} alt="" style={{ maxHeight: "8vh" }} />
                {/* {this.props.t('Extra')} */}
              </Button>
              <Button
                style={{ backgroundColor: `transparent`, borderColor: `transparent` }}
                className="btn btn-light align-button btn-arrow-right">
                <img src={`${window.location.origin + "/wizardprogress3@2x.png"}`} alt="" style={{ maxHeight: "8vh" }} />
                {/* {this.props.t('Overview')} */}
              </Button>
            </div>
            : this.props.active3 ? // webdesign
              <div className="hide-btns">
                <Button
                  style={{ backgroundImage: `url(${window.location.origin + "/button_wizard@2x.png"})`, backgroundSize: 'cover', }}
                  onClick={this.onNavigateHome}
                  className="nav-button home-button">
                  <p className="home-button-text">{this.props.t('Back to Home')}</p>
                </Button>
                <Button
                  // href='/register'
                  onClick={() => this.props.setActive({ active1: false, active2: true, active3: false, active4: false, active5: false })}
                  style={{ backgroundColor: `transparent`, borderColor: `transparent` }}
                  className="btn btn-light align-button btn-arrow-right">
                  <img src={`${window.location.origin + "/progress@2x.png"}`} alt="" style={{ maxHeight: "8vh" }} />
                </Button>
                <Button
                  // href='/web-design'
                  onClick={() => this.props.setActive({ active1: false, active2: false, active3: true, active4: false, active5: false })}
                  style={{ backgroundColor: `transparent`, borderColor: `transparent` }}
                  className="btn btn-light align-button btn-arrow-right">
                  <img src={`${window.location.origin + "/wizardprogress1@2x.png"}`} alt="" style={{ maxHeight: "8vh" }} />
                  {/* {this.props.t('Web design')} */}
                </Button>
                <Button
                  style={{ backgroundColor: `transparent`, borderColor: `transparent` }}
                  className="btn btn-light align-button btn-arrow-right">
                  <img src={`${window.location.origin + "/wizardprogress2@2x.png"}`} alt="" style={{ maxHeight: "8vh" }} />
                  {/* {this.props.t('Extra')} */}
                </Button>
                <Button
                  style={{ backgroundColor: `transparent`, borderColor: `transparent` }}
                  className="btn btn-light align-button btn-arrow-right">
                  <img src={`${window.location.origin + "/wizardprogress3@2x.png"}`} alt="" style={{ maxHeight: "8vh" }} />
                  {/* {this.props.t('Overview')} */}
                </Button>
              </div>
              : this.props.active2 ?
                <div className="hide-btns container-buttons">
                  <Button
                    style={{ backgroundImage: `url(${window.location.origin + "/button_wizard@2x.png"})`, backgroundSize: 'cover', }}
                    onClick={this.onNavigateHome}
                    className="nav-button home-button">
                    <p className="home-button-text">{this.props.t('Back to Home')}</p>
                  </Button>
                  <Button
                    // href='/register'
                    onClick={() => this.props.setActive({ active1: false, active2: true, active3: false, active4: false, active5: false })}
                    style={{ backgroundColor: `transparent`, borderColor: `transparent` }}
                    className="btn btn-light align-button btn-arrow-right">
                    <img src={`${window.location.origin + "/progress@2x.png"}`} alt="" style={{ maxHeight: "8vh" }} />
                    {/* {this.props.t('Business Information')} */}
                  </Button>
                  <Button
                    style={{ backgroundColor: `transparent`, borderColor: `transparent` }}
                    className="btn btn-light align-button btn-arrow-right">
                    <img src={`${window.location.origin + "/wizardprogress1@2x.png"}`} alt="" style={{ maxHeight: "8vh" }} />
                    {/* {this.props.t('Web design')} */}
                  </Button>
                  <Button
                    style={{ backgroundColor: `transparent`, borderColor: `transparent` }}
                    className="btn btn-light align-button btn-arrow-right">
                    <img src={`${window.location.origin + "/wizardprogress2@2x.png"}`} alt="" style={{ maxHeight: "8vh" }} />
                    {/* {this.props.t('Extra')} */}
                  </Button>
                  <Button
                    style={{ backgroundColor: `transparent`, borderColor: `transparent` }}
                    className="btn btn-light align-button btn-arrow-right">
                    <img src={`${window.location.origin + "/wizardprogress3@2x.png"}`} alt="" style={{ maxHeight: "8vh" }} />
                    {/* {this.props.t('Overview')} */}
                  </Button>
                </div>
                : //home
                null
        }
      </div>
    )
  }
}
export default withTranslation('RouteComponent')(RouteComponent);
