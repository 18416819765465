import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import Portrait from '../../images/mug.jpg';
import Portrait2 from '../../images/pictureframe.jpg';
import Portrait3 from '../../images/tshirt.jpg';

function CategoryDemoComponent(props) {
  const useStyles = makeStyles(() => ({
    catlink: {
      color: `${props.primary_color_7} !important`,
      "&:hover": {
          color: `${props.primary_color_7} !important`,
      }
    },
  }));
  const classes = useStyles();

const categories = [
  {
    categorie_id: 5,
    afbeelding: undefined,
    naam: "Categorie zonder afbeelding"
  },
  {
    categorie_id: 1,
    afbeelding: undefined,
    naam: "Categorie zonder afbeelding"
  },
  {
    categorie_id: 2,
    afbeelding: Portrait,
    naam: "Categorie 2 met afbeelding"
  },
  {
    categorie_id: 3,
    afbeelding: Portrait2,
    naam: "Categorie 3 met afbeelding"
  },
  {
    categorie_id: 4,
    afbeelding: Portrait3,
    naam: "Categorie 4 met afbeelding"
  }
]

    return (
      <Row className="sidebar justify-content-left margin-around text-start sticky-state"
      style={{
        fontFamily: `${props.font_family}`,
        border: '1px solid rgb(217 218 219)',
        borderRadius: `${props.border_radius_2}`
      }}>
        <Col className="col-title"
          style={{
            backgroundColor: `${props.background_color_1}`,
            border: `1px solid ${props.background_color_1}`,
            borderRadius: `${props.border_radius_2} ${props.border_radius_2} 0px 0px`,
            color: `${props.primary_color_1}`
          }}>
          <h4 className="sidebar-title">{props.t('category:Categories')}</h4>
        </Col>
        <Col xs={12} sm={12} className="scroll-body scroll-body-height-categories">
            <Col xs={12} sm={12} className="card no-padding-sides">
            {categories.map((category, index) => {
              return <div key={category.categorie_id}>
                <Col xs={12} sm={12} className="top-col no-padding-sides">
                  <Link
                    style={{ float: 'center' }}
                    to="#"
                    className="photos row image-link"
                    value={category.categorie_id}
                    >

                    {category.afbeelding !== null && category.afbeelding !== undefined ?
                      <Col xs={12} sm={12} md={12} className="no-padding-sides">
                        <div className="cat-btn" style={{ backgroundImage: `url(${category.afbeelding})` }}
                        >
                          <div className="cat-image-overlay">
                            <div className={`${classes.catlink} cat-image-text`}
                            style={{ backgroundColor: `#ffffff` }}>
                              {category.naam}
                            </div>
                          </div>
                        </div>
                      </Col>
                    : 
                    <Col xs={12} sm={12} md={12} className="no-padding-sides">
                      <ul>
                        <li className={`${classes.catlink} text-start`} 
                        style={{
                          background: `url('https://cdn4.iconfinder.com/data/icons/spirit20/camera.png') no-repeat left top`,
                          padding: `0px 0px 0px 23px`,
                          listStyle: `none`,
                          margin: `0`
                        }}>
                          {category.naam}
                        </li>
                      </ul>
                    </Col>
                    }

                  </Link>
                </Col>
              </div>
              })}
            </Col>
          </Col>
      </Row>
    );
}

export default withTranslation(['message', 'category'])(CategoryDemoComponent);
