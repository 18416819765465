export const formContent = {
    inputs: [
      {
        id:"domain_name",
        name:"domain_name",
        type:"text",
        autocomplete: undefined,
        label: 'Domain_name',
        helperText: 'Domain_name',
        required: true
      },
      {
        id:"logo",
        name:"logo",
        type:"text",
        autocomplete: undefined,
        label: 'Logo',
        required: false
      },
      {
        id:"company_image",
        name:"company_image",
        type:"text",
        autocomplete: undefined,
        label: 'Company Image',
        helperText: 'Company Image',
        required: false
      },
    ]
  };

  export const formContentSelect = {
    inputs: [
      {
        id:"font_family",
        name:"font_family",
        type:"select",
        autocomplete: undefined,
        label: 'Select Font',
        helperText: 'Select Font',
        required: false
      },
    ]
  }

  export const formContentBR2 = {
    inputs: [
      {
        id:"border_radius_2",
        name:"border_radius_2",
        type:"number",
        autocomplete: undefined,
        label: 'Border Radius Headers',
        helperText: 'Default value',
        min:"0",
        max:"30",
        step:"0.1",
        required: true
      },
    ]
  }

  export const formContentBR1 = {
    inputs: [   
      {
        id:"border_radius",
        name:"border_radius",
        type:"number",
        autocomplete: undefined,
        label: 'Border Radius Buttons',
        helperText: 'Default value',
        min:"0",
        max:"30",
        step:"0.1",
        required: true
      },
    ]
  }

  export const formContentCheckbox = {
    inputs: [
      {
        id:"agree",
        name:"agree",
        type:"checkbox",
        autocomplete: undefined,
        label: 'Agree',
        required: true
      },
    ]
  }