import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from '../locale/en';
import fr from '../locale/fr';
import nl from '../locale/nl';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en,
            fr,
            nl,
        },
        lng: 'nl',
        fallbackLng: 'nl',
        debug: process.env.NODE_ENV !== 'production',
        ns: ['message'],
        defaultNS: 'message',
        keySeparator: false,
        interpolation: {
            escapeValue: false,
            formatSeparator: ',',
        },
        react: {
            wait: false,
        },
    });
export default i18n;


