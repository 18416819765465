import { gql } from 'apollo-boost';

/* THEMES */
export const GET_All_SUBMITTED_DATA = gql`
query GetSite($domain_name: String!, $user_id: ID!){
        siteUserOverview(domain_name: $domain_name, user_id: $user_id){
            site_id
            site_name
            domain_name
            first_category_ID
            user{
              name
              firstname
              middlename
              lastname
              email
            }
            theme{
                site_id
                logo
                primary_color_1
                primary_color_2
                primary_color_3
                primary_color_4
                primary_color_5
                primary_color_6
                primary_color_7
                background_color_1
                background_color_2
                background_color_3
                background_color_4
                background_color_5
                background_color_6
                font_family
                company_image
                border_radius
                border_radius_2
            }
            siteconfig{
                site_id
                type
            }
            siteoptions{
                option_id
                option_value
                option_type
                option_name
                module_name
                module_price
                site_id
            }
        }
    }
`

export const GET_All_THEMES = gql`
    query GetSite($domain_name: String){
        site(domain_name: $domain_name, fotograaf_id: null){
            site_id
            domain_name
            theme {
                site_id
                logo
                primary_color_1
                primary_color_2
                primary_color_3
                primary_color_4
                primary_color_5
                primary_color_6
                primary_color_7
                background_color_1
                background_color_2
                background_color_3
                background_color_4
                background_color_5
                background_color_6
                font_family
                company_image
                border_radius
                border_radius_2
            }
        }
    }`

/* USER */
export const CREATE_USER = gql`
   mutation register($email: String!, $password: String!, $name: String!, $firstname: String, $middlename: String, $lastname: String) {
    register(data:{
    name: $name
    firstname: $firstname
    middlename: $middlename
    lastname: $lastname
    email: $email
    password: $password
    client_id: 2
    # client_secret: "lWUHvnbqpAt6bbh6kfkHpIOVDVp7IGeRzg4d5Pmp"
    client_secret: "B4qLlm3bRViBRU6D0DNyxn0V9XtXzeLJV7iUHErt"
    }) {
    id
    name
    firstname
    middlename
    lastname
    email
    }
  }
`

/* SITE */
export const CREATE_SITE = gql`
mutation createSiteTheme (
    $domain_name: String
    $type: String
    $logo: String
    $primary_color_1: String
    $primary_color_2: String
    $primary_color_3: String
    $primary_color_4: String
    $primary_color_5: String
    $primary_color_6: String
    $primary_color_7: String
    $background_color_1: String
    $background_color_2: String
    $background_color_3: String
    $background_color_4: String
    $background_color_5: String
    $background_color_6: String
    $font_family: String
    $company_image: String
    $border_radius: String
    $border_radius_2: String
){createSiteTheme(input: { 
        domain_name: $domain_name
        type: $type
        theme: {
          logo: $logo
          primary_color_1: $primary_color_1
          primary_color_2: $primary_color_2
          primary_color_3: $primary_color_3
          primary_color_4: $primary_color_4
          primary_color_5: $primary_color_5
          primary_color_6: $primary_color_6
          primary_color_7: $primary_color_7
          background_color_1: $background_color_1
          background_color_2: $background_color_2
          background_color_3: $background_color_3
          background_color_4: $background_color_4
          background_color_5: $background_color_5
          background_color_6: $background_color_6
          font_family: $font_family
          company_image: $company_image
          border_radius: $border_radius
          border_radius_2: $border_radius_2
        } } ) { 
                status
                site_id
                domain_name
            }
  }
  `

export const CREATE_SITE_OPTIONS = gql`
  mutation createSiteOptions($input: [SiteOptionsInput!]){
      createSiteOptions(input: $input){
        option_id
        site_id
        option_type
        option_name
        option_value
        option_view
      }
    }
`

export const GET_SITE_CONFIG_TYPES = gql`
    query getsiteconfigtypes{
        __type(name: "PhotographerType") {
            name
            enumValues {
                name
            }
        }
    }
`;

/* FONTS */
export const GET_All_FONTS = gql`
query fonts {
    fonts {
        id
        font_family
        style
        src
    }
}
`

/* MODULES */

export const GET_All_MODULES = gql`
query modules{
        modules {
        module_id
        module_naam
        tag
        moduleoptions {
            optie_id
            module_id
            naam
            tag
            omschrijving
            type_input
            default
        }
    }
}
`