import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';

import RouteComponent from "../components/Nav/RouteComponent";
import Home from "./home";
import Register from "../screens/register";
import Webdesign from "../screens/webdesign";
// import Modules from "../screens/modules";
import Overview from '../screens/overview';


function Form(props) {
  //const site_id = localStorage.getItem('site_id');
  const [active, setActive] = useState({ active1: false, active2: false, active3: false, active4: false, active5: false });
  const [data, setData] = useState(undefined);

  useEffect(() => {
    setActive(prevState => ({ ...prevState, active1: true }));
    console.log('active1');
  }, [])

  const sendToNextScreen = (props) => {
    setData(props);
  }

  const goBackToLastScreen = () => {
    const active = localStorage.getItem('active');
    switch (active) {
      case 'active2':
        setActive(prevState => ({ ...prevState, active1: false, active2: true, active3: false, active4: false, active5: false }));
      break;
      case 'active3':
        setActive(prevState => ({ ...prevState, active1: false, active2: false, active3: true, active4: false, active5: false }));
      break;
      case 'active4':
        setActive(prevState => ({ ...prevState, active1: false, active2: false, active3: false, active4: true, active5: false }));
      break;
      case 'active5':
        setActive(prevState => ({ ...prevState, active1: false, active2: false, active3: false, active4: false, active5: true }));
      break;
      default:
        setActive(prevState => ({ ...prevState, active1: true, active2: false, active3: false, active4: false, active5: false }));
      break;
    }
  }
  console.log(data);

  const nextScreen = (activated) => {
    if (activated) {
      console.log('active2')
      localStorage.setItem('active', 'active2')
      setActive(prevState => ({ ...prevState, active1: false, active2: activated, active3: false, active4: false, active5: false }));
    }
  }

  const nextScreen2 = (activated) => {
    if (activated) {
      console.log('active3')
      localStorage.setItem('active','active3')
      setActive(prevState => ({ ...prevState, active1: false, active2: false, active3: activated, active4: false, active5: false }));
    }
  }

  const nextScreen3 = (activated) => {
    if (activated) {
      console.log('active4')
      localStorage.setItem('active','active4')
      setActive(prevState => ({ ...prevState, active1: false, active2: false, active3: false, active4: activated, active5: false }));
    }
  }

  // const nextScreen4 = (activated) => {
  //   if (activated) {
  //     console.log('active5')
  //     localStorage.setItem('active','active5')
  //     setActive(prevState => ({ ...prevState, active1: false, active2: false, active3: false, active4: false, active5: activated }));
  //   }
  // }

  return (
    <div className="">

      <RouteComponent
        active1={active.active1}
        active2={active.active2}
        active3={active.active3}
        active4={active.active4}
        active5={active.active5}
        setActive={setActive}
        {...props}
      />

      {active.active4 ?
        <div>
          <Overview
            {...props}
          />
        </div>
        // : active.active4 ?
        //   <div>
        //     {/* active4 */}
        //     <Modules
        //       {...props}
        //       nextScreen4={nextScreen4} />
        //   </div>
          : active.active3 ?
            <div>
              {/* active3 */}
              <Webdesign
                {...props}
                data={data}
                nextScreen3={nextScreen3} />
            </div>
            : active.active2 ?
              <div>
                {/* active2 */}
                <Register
                  {...props}
                  sendToNextScreen={sendToNextScreen}
                  nextScreen2={nextScreen2} />
              </div>
              :
              <div>
                {/* active1 default */}
                <Home
                  {...props}
                  nextScreen={nextScreen} 
                  goBackToLastScreen={goBackToLastScreen}
                  />
              </div>
      }
    </div >
  );
}

export default withTranslation(['message'])(Form);
