import * as yup from 'yup'

const empty_field = "EmptyField"
const too_big = "Toobig"
const too_small = "Toosmall"

// helper for yup transform function
function emptyStringToNull(value, originalValue) {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null;
  } 
  return value;
}

export const WebdesignSchema = yup.object().shape({
  domain_name: yup.string().required(empty_field),
  logo: yup.string(),
  company_image: yup.string(),
  font_family: yup.string().required(empty_field),
  border_radius_2: yup.number('nummer').min(0, too_small).max(30, too_big).transform(emptyStringToNull).nullable().required(empty_field),
  border_radius: yup.number('nummer').min(0, too_small).max(30, too_big).transform(emptyStringToNull).nullable().required(empty_field),
  agree: yup.bool().oneOf([true], empty_field),
});
