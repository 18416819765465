export const formContent = {
    inputs: [
      {
        id: "firstname",
        name:"firstname",
        type:"text",
        autocomplete:"given-name",
        label: 'Firstname',
        helperText: 'Firstname',
        required: true
      },
      {
        id: "middlename",
        name:"middlename",
        type:"text",
        autocomplete:"given-name",
        label: 'Middlename',
      },
      {
        id: "lastname",
        name:"lastname",
        type:"text",
        autocomplete:"family-name",
        label: 'Lastname',
        helperText: 'Lastname',
        required: true
      },
      {
        id: "email",
        name:"email",
        type:"text",
        autocomplete:"email",
        label: 'E-mail address',
        helperText: 'Email',
        required: true
      },
      {
        id: "email_validation",
        name:"email_validation",
        type:"text",
        autocomplete:"email",
        label: 'Email validation',
        helperText: 'Email validation',
        required: true
      },
      {
        id: "password",
        name:"password",
        type: "password",
        autocomplete:"new-password",
        label: 'Password',
        helperText: 'Enter Password',
        required: true
      }, 
      {
        id: "password_validation",
        name:"password_validation",
        type:"password",
        autocomplete:"new-password",
        label:'Password validation',
        helperText: 'Password validation',
        required: true
      },
      {
        id: "name",
        name:"name",
        type:"text",
        autocomplete:"organization",
        label: 'Name',
        helperText: 'NameP',
        required: true
      },
      {
        id:"address",
        name:"address",
        type:"text",
        autocomplete: 'address-line3',
        label: 'Address',
        helperText: 'AddressP',
      },
      {
        id:"zipcode",
        name:"zipcode",
        type:"text",
        autocomplete: 'postal-code',
        label: 'Zipcode',
        helperText: 'ZipcodeP',
        max:"6",
        min:"6"
      },
      {
        id: "photographer_type",
        name:"photographer_type",
        type: "select",
        autocomplete: undefined,
        label: 'Photographer type',
        helperText: 'Photographer typeP',
        required: true
      },
      {
        id: "photography_type",
        name:"photography_type",
        type: "select",
        autocomplete: undefined,
        label: 'Photography types',
        helperText: 'Photography typesP'
      },
      {
        id: "experience",
        name:"experience",
        type:"text",
        autocomplete: undefined,
        label: 'Years of experience',
        helperText: 'Years of experienceP',
        required: true
      },
      {
        id:"agree_with_terms",
        name:"agree_with_terms",
        type:"checkbox",
        autocomplete: undefined,
        label: 'agree',
        required: true
      },
    ]
  }
