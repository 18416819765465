import React from 'react';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Col, Nav, Dropdown, NavItem, Navbar } from "react-bootstrap";
import Button from '@material-ui/core/Button';


function NavBarDemoComponent(props) {
    const login_name= localStorage.getItem('login_name');

    const useStyles = makeStyles(() => ({
      navlink: {
          color: `${props.primary_color_1} !important`,
          "&:hover": {
              color: `${props.primary_color_6} !important`,
          }
      },
    }));
    const classes = useStyles();
    return (

      <Navbar collapseOnSelect expand="md" className="navbar navbar-light"
        style={{
          fontFamily: `${props.font_family}`,
          backgroundColor: `${props.background_color_1}`,
          color: `${props.primary_color_1}`,
          maxWidth: '-webkit-fill-available'
        }}>

        <Navbar.Toggle aria-controls="basic-navbar-nav text-start" />
        <Col xl={2} lg={2} md={4} sm={12} xs={12}>
          <Navbar.Brand className="navbar-brand">
            <img
              src={`${window.location.origin + "/new_fpt_logo@2x.png"}`}
              className="navbar-brand-logo-demo"
              alt="site logo"
            />
          </Navbar.Brand>
        </Col>
        <Col xl={7} lg={7} md={1} sm={12} xs={12}>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="navbar-links">
              <ul className={`${classes.navlink} navbar-nav`}>
                    <li className={`${classes.navlink} nav-item`}>
                        <NavLink 
                            exact to="#"
                            className={`${classes.navlink} nav-link`}
                            >
                              {props.t('navbar:Contact')}
                        </NavLink>
                    </li>
                    <li className={`${classes.navlink} nav-item`}>
                        <NavLink 
                            exact to="#"
                            className={`${classes.navlink} nav-link`}>
                          {props.t('navbar:Home')}
                        </NavLink>
                    </li>
                  <Dropdown 
                    className="top-level padding-top-2" 
                    as={NavItem}
                    id="collapsible-nav-dropdown">
                        <Dropdown.Toggle className={`${classes.navlink} hide-button`}
                        style={{textTransform: 'uppercase'}}
                        >
                            {props.t('navbar:My Login')}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                        <Dropdown.Item 
                            className="nav-link padding-left-1rimp no-padding font-size-small" 
                            style={{color: `${props.primary_color_1}`, 
                            backgroundColor: `${props.background_color_1}`}}
                        >
                                {props.t('home:Login')}: {login_name}
                        </Dropdown.Item>
                        <Dropdown.Divider />
                            <>
                                <Dropdown.Item
                                    className="nav-link nav-link-layout padding-left-1rimp">
                                    <NavLink exact to="#">{props.t('navbar:Create Account')}</NavLink>
                                </Dropdown.Item>
                                <Dropdown.Item 
                                    className="nav-link nav-link-layout padding-left-1rimp">
                                    <NavLink exact to="#">{props.t('navbar:Match Login Ticket')}</NavLink>
                                </Dropdown.Item>
                            </>
                        
                        <Button disabled
                            className="nav-link nav-link-layout padding-left-1rimp"
                            style={{ fontFamily: `${props.font_family}` }}
                        >{props.t('navbar:Logout')}
                        </Button>
                        </Dropdown.Menu>
                    </Dropdown>
              </ul>
            </Nav>
          </Navbar.Collapse>
        </Col>
      </Navbar>

    );
}

export default withTranslation(['message', 'navbar'])(NavBarDemoComponent);
