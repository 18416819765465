import React from 'react';
// import { Button } from "react-bootstrap";
// import { Link } from 'react-router-dom';
import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss';

class ColorPickComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      displayColorPicker: false,
      color: '#000'
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.color !== prevState.color) {
      this.setState({ color: prevProps.color });
    }
    if (prevProps.color !== this.props.color) {
      this.setState({ color: this.props.color });
    }
  }

  handleChangeComplete = (color) => {
    const name = this.props.name;
    this.setState({ color: color.hex });
    this.props.colorPicked(name, this.state.color)
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  };

  render() {
    const styles = reactCSS({
      'default': {
        color: {
          // width: '36px',
          width: '-webkit-fill-available',
          // height: '14px',
          height: '2vh',
          borderRadius: '2px',
          background: this.state.color,
          border: '1px solid black'
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          // display: 'inline-block',
          display: 'flex',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });
    return (
      <div className="text-align-center">

        <div style={styles.swatch} onClick={this.handleClick}>
          <div style={styles.color} />
        </div>
        {this.state.displayColorPicker ? <div style={styles.popover}>
          <div style={styles.cover} onClick={this.handleClose} />
          <SketchPicker color={this.state.color} onChangeComplete={this.handleChangeComplete} />
        </div> : null}

      </div>
    );
  }
}

export default ColorPickComponent;


