import React from 'react';
import { withTranslation } from 'react-i18next';


function CompanyDetails(props) {

  return (
    <div className="container webdesign no-padding-left"
      style={{
        fontFamily: `${props.font_family}`,
        color: `black`,
      }}>
      {props.data !== null &&
        <>
          <p>Bedrijfsnaam: {props.data.user.name}</p>
          <p>Type fotograaf/bedrijf: {props.t(`register:${props.data.siteconfig.type}`)}</p>
          <p>Uw naam: {props.data.user.firstname} {props.data.user.middlename} {props.data.user.lastname}</p>
          <p>Email: {props.data.user.email}</p>
        </>
      }
    </div>
  )
}
export default withTranslation(['message', 'overview', 'register'])(CompanyDetails);