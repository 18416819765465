import React from 'react';

import { withTranslation } from 'react-i18next';


class FontDemoComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {


    return (
      <div style={{
        fontFamily: `${this.props.font_family}`,
        color: `${this.props.primary_color_2}`, maxWidth: "95%"
      }}>
        <h5>{this.props.font_family}</h5>
        <p style={{ fontFamily: `${this.props.font_family}` }}
          className="left-webkit-text-align">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed elit justo, laoreet a vestibulum posuere, fringilla nec quam. In vel elit justo. Quisque et metus ipsum. Aenean at turpis tempor, dictum massa nec, ullamcorper ligula. Curabitur ac viverra orci, a gravida ipsum. Sed aliquam at purus rutrum laoreet. Sed eu odio id risus varius elementum non quis enim. Phasellus vestibulum lacus sed nulla pharetra, id euismod tellus consectetur. Nullam nec laoreet metus. Maecenas a quam sagittis, posuere neque sit amet, tempor ex. Aliquam erat volutpat. Praesent efficitur tempus est, in hendrerit ante maximus quis. Nam eget tristique odio, id dignissim elit. Etiam mattis, nisi quis pretium semper, urna lorem posuere leo, id pulvinar nisl leo eu diam. Phasellus egestas tincidunt malesuada. Etiam tempor libero quis tellus hendrerit varius.
      </p>
      </div>
    );
  }
}

export default withTranslation(['message'])(FontDemoComponent);
