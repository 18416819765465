import React from 'react';
import Form from 'react-bootstrap/Form';
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { withTranslation } from 'react-i18next';

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  render() {
    return (
      <div className="container contact">
        <h2>Contact</h2>
        <Card className="col" style={{
          fontFamily: `${this.props.font_family}`,
          color: `${this.props.color2}`
        }}>
          <Card.Header
            style={{
              backgroundColor: `${this.props.backgroundColor1}`
            }}
            className="row">
            <div className="col-6 left-text-align" >
              <Card.Title>{this.props.domain_name}</Card.Title>
              <Card.Text className="no-margin-bttm">mussenstraat 55</Card.Text>
              <Card.Text className="no-margin-bttm">1223 RB Hilversum</Card.Text>
              <Card.Text className="no-margin-bttm">035 - 20 33 013</Card.Text>
              <Card.Text className="no-margin-bttm">contact@blacksheepz-media.com</Card.Text>
              <Card.Text >KvK: 64700267</Card.Text>
            </div>
            <div className="col-6">
            </div>
          </Card.Header>
          <Card.Body className="left-text-align">
            <Form>
              <Form.Group controlId="exampleForm.ControlInput1">

                <Form.Control type="name" placeholder={this.props.t('Your Name')} />
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlInput2">

                <Form.Control type="email" placeholder={this.props.t('Your Email')} />
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlInput3">

                <Form.Control type="text" placeholder={this.props.t('Subject')} />
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlTextarea1">

                <Form.Control as="textarea" rows="3" placeholder={this.props.t('Message')} />
              </Form.Group>
              <Button className="nav-button"
                style={{ color: `${this.props.color3}`, backgroundColor: `${this.props.backgroundColor2}`, borderColor: `${this.props.backgroundColor2}` }}>
                {this.props.t('Submit')}

              </Button>
            </Form>
          </Card.Body>
        </Card>

      </div>
    );
  }
}

export default withTranslation('contact')(Contact);
