import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';

function ExtraModules(props) {

  const [data, setData] = useState([]);

  useEffect(() => {
    setData(props.data.siteoptions.filter(m => m.option_type === 'others' && m.option_value === 'true'))
  }, [props.data.siteoptions])

  return (
    <div className="container extraModules no-padding-left"
      style={{
        fontFamily: `${props.font_family}`,
        color: `black`,
      }}>
      {data && data.map(m => (
        <Row key={m.option_id}>
          <Col>
            <p style={{ textTransform: 'capitalize' }}>
              Module: {m.option_name.replaceAll('_', ' ')}
            </p>
          </Col>
          <Col>
            <p>
              Prijs: € geen prijs bekend
            </p>
          </Col>
        </Row>
      ))
      }
      <Row>
        <Col>
          <p>Totaal extra kosten: € prijs is niet bekend</p>
        </Col>
      </Row>
    </div>
  )
}
export default withTranslation(['message', 'overview'])(ExtraModules);