import * as yup from 'yup'

const password_validation_message = "SafePassword";
const password_regex = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})";

const postcode_validation_message = "Postcode validation";
const postcode_regex = new RegExp("^[1-9][0-9]{3}[A-Za-z]{2}$");

const empty_field = "EmptyField"

export const RegistrationSchema = yup.object().shape({
  firstname: yup.string().required(empty_field),
  middlename: yup.string(),
  lastname: yup.string().required(empty_field),
  email: yup.string().required(empty_field).email("ErrorEmail"),
  email_validation: yup.string().required(empty_field).email("ErrorEmail").oneOf([yup.ref('email'), null], 'Validate Error Email'),
  password: yup.string().required(empty_field).matches(password_regex, password_validation_message),
  password_validation: yup.string().required(empty_field).matches(password_regex, password_validation_message).oneOf([yup.ref('password'), null], 'Validate Error Password'),
  name: yup.string().required(empty_field),
  klant_plaats: yup.string(),
  klant_postcode: yup.string().matches(postcode_regex, postcode_validation_message),
  photographer_type: yup.string().required(empty_field),
  photography_type: yup.string(),
  experience: yup.string().required(empty_field),
  agree_with_terms: yup.bool().oneOf([true], empty_field),
});
