import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { withTranslation } from 'react-i18next';
import { Col, Row, Image } from "react-bootstrap";
import Button from '@material-ui/core/Button';
import Portrait from '../../images/example_portrait.jpg';
import Portrait2 from '../../images/girl_portrait.jpg';
import Portrait3 from '../../images/horse_portrait.jpg';

function HomePhotoDemoComponent(props) {

  const photo_array = [
    {
      id: 1,
      url: Portrait,
    },
    {
      id: 3,
      url: Portrait3,
    },
    {
      id: 2,
      url: Portrait2,
    }
  ];

  const [border_radius_2, setBorderRadius] = useState(props.border_radius_2);

  useEffect(() => {
    if (props.border_radius_2 !== undefined && border_radius_2 !== props.border_radius_2) {
      setBorderRadius(`${props.border_radius_2}px`);
    }
  }, [border_radius_2, props.border_radius_2, setBorderRadius])

  return (
    <Row className="bottom-card margin-around"
      style={{
        fontFamily: `${props.font_family}`,
        color: `${props.primary_color_2}`
      }}>
      <Row style={{
        border: '1px solid rgb(217 218 219)',
        borderRadius: `${border_radius_2} ${border_radius_2} ${border_radius_2} ${border_radius_2}`
      }} className="bottom-card photosComponent-row">
        <Col lg={12} style={{
          border: `1px solid ${props.background_color_1}`,
          backgroundColor: `${props.background_color_1}`,
          borderRadius: `${border_radius_2} ${border_radius_2} 0px 0px`
        }} className="bottom-card">
          <h3 className="text-start" style={{color: `${props.primary_color_1}`}}>
            <span>{props.t('homePhotoDemoComponent:Portrait photo(s)')}</span>
          </h3>
        </Col>
        {photo_array.map(p => (
          <Col xs={12} md={12} lg={6} xl={3} className="text-center bottom-card" key={p.id}>
            <Link style={{ float: `left` }}
              className="photos image-link">
              <Image style={{ border: `1px solid ${props.background_color_5}` }}
                src={`${p.url}`}
                className="small-image-size image-home foto1"
              />
              <div className="image-overlay" style={{ backgroundColor: `${props.background_color_5}` }}>
                <div className="image-text">
                  <Button
                    className="width-fit-content align-around home-button"
                    style={{
                      fontFamily: `${props.font_family}`,
                      color: `${props.primary_color_4}`,
                      backgroundColor: `${props.background_color_2}`,
                      borderColor: `${props.background_color_2}`,
                      borderRadius: `${props.border_radius}`,
                      maxWidth: `50%`
                    }}
                  >
                    {props.t('homePhotoDemoComponent:View your photo')}
                  </Button>
                </div>
              </div>
            </Link>
          </Col>
        ))}

      </Row>
    </Row>
  );
}

export default withTranslation('homePhotoDemoComponent')(HomePhotoDemoComponent);
