import React from 'react';
import { withTranslation } from 'react-i18next';

function Webdesign(props) {

  return (
    <div className="container companydetails no-padding-left"
      style={{
        fontFamily: `${props.font_family}`,
        color: `black`,
      }}>
      <p>{props.t('webdesign:Domain_name')}: {props.data.domain_name}</p>

      <h5 className="font-bold top-page">{props.t('webdesign:Font style')}</h5>
      <p>{props.t('webdesign:Font Family')}: {props.data.theme.font_family}</p>

      {props.t('webdesign:Primary Font Color')}:
      <div style={{ height: '1rem', backgroundColor: props.data.theme.primary_color_2, boxShadow: 'black 1px 1px 5px' }} />

      <h5 className="top-page font-bold ">{props.t('webdesign:Background Color Webpage')}</h5>
      <div style={{ height: '1rem', backgroundColor: props.data.theme.background_color_6, boxShadow: 'black 1px 1px 5px' }} />

      <h5 className="top-page font-bold ">{props.t('webdesign:Border Color photo')}</h5>
      <div style={{ height: '1rem', backgroundColor: props.data.theme.background_color_5, boxShadow: 'black 1px 1px 5px' }} />

      <h5 className="top-page font-bold ">{props.t('webdesign:Navigation, head and pedestals')}</h5>
      <div style={{ height: '1rem', backgroundColor: props.data.theme.background_color_1, boxShadow: 'black 1px 1px 5px' }} />
      <p>{props.t('webdesign:Border Radius Headers')}: {props.data.theme.border_radius_2}</p>

      <h5 className="top-page font-bold ">{props.t('webdesign:Buttons')}</h5>

      {props.t('webdesign:Font Color Buttons')}:
      <div style={{ height: '1rem', backgroundColor: props.data.theme.primary_color_4, boxShadow: 'black 1px 1px 5px' }} />

      {props.t('webdesign:Background and BorderColor Buttons')}:
      <div style={{ height: '1rem', backgroundColor: props.data.theme.background_color_2, boxShadow: 'black 1px 1px 5px' }} />

      <p>{props.t('webdesign:Border Radius Button')}: {props.data.theme.border_radius}</p>

      <h5 className="top-page font-bold ">Producten</h5>
      {props.t('webdesign:Left part header products in shoppage')}:
      <div style={{ height: '1rem', backgroundColor: props.data.theme.background_color_3, boxShadow: 'black 1px 1px 5px' }} />

      {props.t('webdesign:Right part header products in shoppage, borders cart')}:
      <div style={{ height: '1rem', backgroundColor: props.data.theme.background_color_4, boxShadow: 'black 1px 1px 5px' }} />

      {props.t('webdesign:Left text header products in shoppage')}:
      <div style={{ height: '1rem', backgroundColor: props.data.theme.primary_color_3, boxShadow: 'black 1px 1px 5px' }} />

      {props.t('webdesign:Right text header products in shoppage')}:
      <div style={{ height: '1rem', backgroundColor: props.data.theme.primary_color_5, boxShadow: 'black 1px 1px 5px' }} />


    </div>
  )
}
export default withTranslation(['message', 'overview', 'webdesign'])(Webdesign);