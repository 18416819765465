import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import { Col, Row, Card, Form } from 'react-bootstrap';

import { useQuery, useMutation } from '@apollo/client';
import { GET_All_MODULES, CREATE_SITE_OPTIONS } from './../queries';

function Modules(props) {

  const [form, setForm] = useState({ modules: [] });
  const { loading: loadingModules, error: errorModules, data: dataModules } =
    useQuery(GET_All_MODULES);

  const [createSiteOptions,
    { loading: loadingcreateSiteOptions, error: errorcreateSiteOptions }]
    = useMutation(CREATE_SITE_OPTIONS, {
      onCompleted: (data) => {
        console.log(data);
      },
      onError: (error) => {
        console.log(error);
      }
    });

  useEffect(() => {
    if (form.modules.length < 1) {
      if (!loadingModules && !errorModules) {
        if (dataModules) {
          setForm({ modules: dataModules.modules });
        }
      }
    }
  }, [form.modules.length, loadingModules, errorModules, dataModules])

  const handleChange = (e, index, indexP) => {
    const { type, name, value } = e.target;
    console.log(type, name, value, indexP, index);
    if (type === 'checkbox') {
      if (name === 'default') {
        let oldArray = form.modules;
        let modules = oldArray.map((a, i) =>
          i === indexP ?
            {
              module_id: a.module_id,
              module_naam: a.module_naam,
              moduleoptions: a.moduleoptions.map((o, id) =>
                id === index ?
                  {
                    optie_id: o.optie_id,
                    module_id: o.module_id,
                    naam: o.naam,
                    omschrijving: o.omschrijving,
                    tag: o.tag,
                    type_input: o.type_input,
                    default: o.default === true || o.default === 'true' ? false : true
                  }
                  : o
              )
            } : a
        )
        setForm({ modules: modules });
      } else {
        setForm(prevState => ({ ...prevState, [name]: value }));
      }
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    let newModule = form.modules.map((m, i) => (
      m.moduleoptions.map((o, index) => (
        {
          option_value: o.default === false ? false : o.default,
          option_type: 'others',
          option_name: o.tag,
          option_view: 'public',
          site_id: localStorage.getItem('site_id')
        }
      )
      )
    ));

    let newModules = newModule.filter(f => f.length >= 1);
    let newArray = [];
    newModules.map((m, i) => newArray = newArray.concat(m));
    console.log(newArray);
    console.log(newModules);
    if (newModules.length > 0) {
      if (!loadingcreateSiteOptions && !errorcreateSiteOptions) {
        createSiteOptions({
          variables: {
            input: newArray
          }
        })
        props.nextScreen4(true);
      }
    }
  }

  console.log(form.modules);

  return (
    <div className="container home"
      style={{
        fontFamily: `${props.font_family}`,
        color: `${props.primary_color_2}`,
      }}>
      <Card className="text-align-left">
        <Card.Header>
          <Row>
            <div className="padding-bottom-3r">
              <Col
                md={12}
                className="no-padding"
                style={{
                  borderImage: `url(${window.location.origin + "/button_wizard_2@2x.png"}) 30% round`,
                  borderBottom: "6px solid transparent"
                }}
              >
                <h2 className="home-title black-text" >
                  {props.t('modules:Extra modules')}
                </h2>
              </Col>
            </div>
            <Col md={12}>
              <Row>
                <p className="col-6 black-text">
                Naast de vele opties die standaard in uw bestelsite worden ingebouwd, is Fotoprotools gevuld met een breed scala aan modules om meer omzet te genereren en uw klanten tevreden te houden.
                </p>
                <p className="col-6 black-text">
                Wanneer u een module wilt toevoegen aan uw bestelsite, klikt u in het onderstaande overzicht op "implementeer".
                Nog niet zeker of u een module wilt toevoegen? Geen probleem! Middels het CMS-Systeem kunt u eenvoudig op een later moment modules wijzigen
                </p>
              
              </Row>
            </Col>
          </Row>
        </Card.Header>

        <Card.Body>
          <Form className="row" onSubmit={(e) => handleSubmit(e)}>

            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="bottom-card">
              <Row className="justify-content-between">
                {form.modules.map((a, index) => (
                  a.moduleoptions.length > 1 ?
                    <Col xs={12} sm={6} md={6} lg={3} xl={3} key={a.module_id} className="module-card card">
                      <Card.Title className="black-text">{a.module_naam}</Card.Title>
                      {a.moduleoptions.map((o, i) => (
                        <Card key={o.optie_id} className="bottom-card">
                          <Card.Body>
                            <Card.Subtitle className="font-bold black-text">{o.naam}</Card.Subtitle>
                            <Card.Text className="black-text">{o.omschrijving}</Card.Text>
                            <Form.Check
                              type="checkbox"
                              id={o.optie_id}
                              name="default"
                              checked={o.default === 'true' || o.default === true ? true : false}
                              onChange={(e) => handleChange(e, i, index)}
                              className="black-text"
                              label={props.t('modules:Deploy')}
                            >
                            </Form.Check>
                          </Card.Body>
                        </Card>
                      ))}

                    </Col>
                    : null // hide empty modules
                ))}
              </Row>
            </Col>

            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="bottom-card">
              <Form.Check
                type="checkbox"
                id={form.agree}
                name="agree"
                checked={form.agree}
                onChange={(e) => handleChange(e)}
                className="black-text"
                label={props.t('modules:Agree')}
              >
              </Form.Check>
            </Col>

            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="top-page">
              <Button
                name='submit'
                type='submit'
                className="nav-button"
                style={{ backgroundImage: `url(${window.location.origin + "/button_wizard_2_dh@2x.png"})`, backgroundSize: 'cover', }}
              >
                <p className="modules-button-text black-text">{props.t('Continue')}</p>
              </Button>
            </Col>
          </Form>
        </Card.Body>
      </Card>
    </div >
  );

}

export default withTranslation(['message', 'modules'])(Modules);
