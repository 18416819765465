import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {IconButton, Button, TextField, Grid, LinearProgress, FormLabel, FormHelperText } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

import Card from "react-bootstrap/Card";
import Alert from "@material-ui/lab/Alert";

import PopUpComponent from "../components/Nav/PopUpComponent";
import ColorPickComponent from '../components/ColorPickComponent';
import NavBarDemoComponent from '../components/Demos/NavBarDemoComponent';
import ProductDemoComponent from '../components/Demos/ProductDemoComponent';
import CategoryDemoComponent from '../components/Demos/CategoryDemoComponent';
import FontDemoComponent from '../components/Demos/FontDemoComponent';
import HomePhotoDemoComponent from '../components/Demos/HomePhotoDemoComponent';

import { formContent, formContentSelect, formContentBR1, formContentBR2, formContentCheckbox } from "../statics/WebDesignFormData";
import { WebdesignSchema } from "./../validations/WebdesignValidation";

import { useMutation, useQuery } from '@apollo/client';
import { CREATE_SITE, GET_All_FONTS } from './../queries';

const useStyles = makeStyles((theme) => ({
  label: {
    color: 'black'
  }
}));

function WebDesign(props) {
  const classes = useStyles();

  const initialThemeState = {

      domain_name: undefined,

      company_image: undefined,
      logo: undefined,

    primary_color_1: undefined,
    primary_color_2: undefined,
    primary_color_3: undefined,
    primary_color_4: undefined,
    primary_color_5: undefined,
    primary_color_6: undefined,
    primary_color_7: undefined,
    background_color_1: undefined,
    background_color_2: undefined,
    background_color_3: undefined,
    background_color_4: undefined,
    background_color_5: undefined,
    background_color_6: undefined,
    font_family: undefined,
    border_radius: undefined,
    border_radius_2: undefined,
  }

  const [theme, setTheme] = useState(initialThemeState);
  const [validation, setValidation] = useState({agree: undefined, font_family: undefined});
  const [messages, setMessages] = useState({ messagePFC: false, messageNavbar: false, messageProduct: false });
  const [validated, setValidated] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onBlur",
    defaultValues: initialThemeState,
    resolver: yupResolver(WebdesignSchema),
  });

console.log(theme)

  useEffect(() => {
    if (theme.primary_color_1 === undefined) {
      let defaultTheme = undefined;
      if (props.border_radius_2 !== undefined) {
      const border_radius_2 = props.border_radius_2.replace('px', '');
      const border_radius = props.border_radius.replace('px', '');
      defaultTheme = {
        ...props,
        domain_name: undefined,
        company_image: undefined,
        logo: undefined,
        border_radius: Number(border_radius),
        border_radius_2: Number(border_radius_2)
      };
    } else {
      defaultTheme = {
      domain_name: undefined,
      company_image: undefined,
      logo: undefined,
        ...props
      };
    }
      setTheme(defaultTheme);
    }
  }, [theme.primary_color_1, props])

  useEffect(() => {
      if (theme.font_family === "undefined") {
        setValidation({font_family: false})
      } else {
        setValidation({font_family: true})
      }
  }, [theme.font_family])

  const setLocals = (data) => {
    localStorage.setItem('site_id', data.createSiteTheme.site_id)
    localStorage.setItem('domain_name', data.createSiteTheme.domain_name)
  }

  const [createSiteTheme, { loading: loadingCreateSiteTheme, error: errorCreateSiteTheme }]
    = useMutation(CREATE_SITE, {
      onCompleted: (data) => {
          return (
            setLocals(data)
          )
      },
      onError: (error) => {
        console.log(error);
      }
    });


  const { loading: loadingFonts, error: errorFonts, data: dataFonts } =
    useQuery(GET_All_FONTS);

  const closePopup = () => {
    setMessages({ messageNavbar: false, messagePFC: false, messageProduct: false });
  }

  const UpdateTheme = (name, value) => {
    setTheme(prevState => ({ ...prevState, [name]: value }));
  }

  const onSubmit = (e) => {
      console.log(e)

      if (!loadingCreateSiteTheme && !errorCreateSiteTheme) {
        createSiteTheme({
          variables: {
            site_id: 0, //test
            domain_name: e.domain_name,
            type: props.data.type,
            logo: e.logo,
            primary_color_1: theme.primary_color_1,
            primary_color_2: theme.primary_color_2,
            primary_color_3: theme.primary_color_3,
            primary_color_4: theme.primary_color_4,
            primary_color_5: theme.primary_color_5,
            primary_color_6: theme.primary_color_6,
            primary_color_7: theme.primary_color_7,
            background_color_1: theme.background_color_1,
            background_color_2: theme.background_color_2,
            background_color_3: theme.background_color_3,
            background_color_4: theme.background_color_4,
            background_color_5: theme.background_color_5,
            background_color_6: theme.background_color_6,
            font_family: e.font_family,
            company_image: e.company_image,
            border_radius: `${theme.border_radius}px`,
            border_radius_2: `${theme.border_radius_2}px`
          }
        });
         console.log(props.data);
        props.nextScreen3(true)
     }
      setValidated(true);
  }

  return (
    <Grid container className="webdesign"
      style={{
        fontFamily: `${props.font_family}`,
        color: `${props.primary_color_2}`,
      }}>

      <Card className="text-align-left">
        <Card.Header>
          <Grid container>
            <div className="padding-bottom-3r">
              <Grid item md={12}
                className="no-padding"
                style={{
                  borderImage: `url(${window.location.origin + "/button_wizard_2@2x.png"}) 30% round`,
                  borderBottom: "6px solid transparent"
                }}
              >
                <h2 className="home-title black-text" >
                  {props.t('webdesign:Webdesign')}
                </h2>
              </Grid>
            </div>

            <Grid item md={12}>
              <Grid container>
                <Grid item lg={6} className="black-text">
                Middels onderstaande opties kunt u uw bestelsite en/of webshop binnen FotoProTools zelf vormgeven. Kies de kleuren en lettertypen die passen bij uw bestaande huisstijl of ontwikkelt middels onderstaande opties een nieuwe.
                </Grid>
                <Grid item lg={6} className="black-text">
                Wanneer u wijzigingen aanbrengt in de onderstaande opties, kunt u middels een preview aan de rechterkant, direct de wijzigingen inzien. Wel zo handig!
                </Grid>
              </Grid>
            </Grid>

          </Grid>
        </Card.Header>

        <Card.Body>
          <form
            noValidate
            validated={validated}
            onSubmit={handleSubmit(onSubmit)}
            >

            <Grid container>
              <Grid item lg={12} md={12} className="font-bold text-align-left" controlId="formGridTitle">
                <h4 className="font-bold black-text">{props.t('webdesign:Basic data')}</h4>
              </Grid>
            </Grid>

            <Grid container spacing={1} className="text-align-left bottom-card" >
            {formContent.inputs.map((input, key) => (
              <Grid item lg={4} md={4} sm={12} xs={12} key={key}>
                <TextField
                  {...register(input.name, { shouldUnregister: true })}
                  id={input.id}
                  autoFocus={key === 0}
                  type={input.type}
                  label={props.t(`webdesign:${input.label}`)}
                  autoComplete={input.autocomplete}
                  className="mb-2 webdesign"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => {
                    const {name, value} = e.target;
                    setTheme(prevState => ({ ...prevState, [name]: value })) 
                  }}
                  helperText={
                    errors[input.name]
                      ? props.t(`webdesign:${errors[input.name].message}`)
                      : input.id === "domain_name" ? 
                      input.helperText &&
                        `https://www.${props.t(`webdesign:${input.helperText}`)}.nl`
                      :
                      input.helperText &&
                      props.t(`webdesign:${input.helperText}`)
                  }
                  error={errors[input.name]}
                  required={input.required}
                  >
                  </TextField>
              </Grid>
              ))}

            </Grid>

            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12} className="dark-border-right" >
                <>
                  <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12} className="font-bold" controlId="formGridTitle">
                      <h5 className="font-bold black-text">{props.t('webdesign:Font style')}

                        <IconButton 
                          onClick={() => setMessages(prevState => ({ ...prevState, messagePFC: !messages.messagePFC }))}>
                          < HelpOutlineIcon style={{ color: `black`, backgroundColor: `transparent`, borderColor: `transparent`, fontSize: `smaller` }}/>
                        </IconButton>

                        {messages.messagePFC ?
                          <PopUpComponent
                            primary_color_4={props.primary_color_4}
                            backgroundColor2={props.background_color_2}
                            border_radius={props.border_radius}
                            text={<Alert
                              variant="info">{props.t('webdesign:Font Family')}, {props.t('webdesign:Primary Font Color')}
                            </Alert>}
                            content={<FontDemoComponent
                              {...theme}
                             />}
                            btn2={props.t('Continue')}
                            closePopup={() => closePopup()}
                          />
                          : null}
                      </h5>
                    </Grid>
                  </Grid>
                  <Grid container className="light-border-bottom bottom-card">
                    {formContentSelect.inputs.map((input, key) => (
                      <Grid item lg={12} md={12} sm={12} xs={12} key={key}>
                        <TextField
                          {...register(input.name, { shouldUnregister: true })}
                          id={input.id}
                          type={input.type}
                          label={props.t(`webdesign:${input.label}`)}
                          autoComplete={input.autocomplete}
                          value={input.type === "select" ? theme[input.id] : null}
                          className="mb-2 webdesign"
                          variant="outlined"
                          fullWidth
                          InputLabelProps={{
                            shrink:
                              input.type === "select"
                                ? true : undefined,
                          }}
                          select={input.type === "select"}
                          SelectProps={
                            input.type === "select" && {
                              native: true,
                            }
                          }
                          onChange={(e) =>
                            setTheme((prevState) => ({
                              ...prevState,
                              [input.id]: e.currentTarget.value,
                            }))
                          }
                          helperText={
                            errors[input.name] ? 
                              props.t(`webdesign:${errors[input.name].message}`)
                              : validation[input.name] === false ?
                               props.t(`webdesign:EmptyField`)
                              : input.helperText &&
                                props.t(`webdesign:${input.helperText}`)
                          }
                          error={errors[input.name] || validation[input.name] === false}
                          required={input.required}
                          >
                          {input.type === "select" && loadingFonts ? 
                          <LinearProgress
                            className="align-self-center"
                            style={{ color: props.primary_color_2 }}
                          />
                          :
                          <>
                            <option value="undefined">Select</option>
                          {!loadingFonts && !errorFonts && dataFonts && dataFonts.fonts.map(font => (
                          font.style === "Regular" ?
                            <option
                              style={{
                                fontFamily: `${font.font_family}`
                              }}
                              value={font.font_family}
                              key={font.id}
                            >
                              {font.font_family} {font.style}
                            </option>
                            : null
                            ))}
                          </>
                          }
                          </TextField>
                      </Grid>
                      ))}
                    
                    <Grid item lg={12} md={12} sm={12} xs={12}  controlId="formGridPrimaryColor2">
                      <FormLabel className={classes.label}>{props.t('webdesign:Primary Font Color')}</FormLabel>
                      {theme.primary_color_2 ?
                        <FormHelperText>{theme.primary_color_2}</FormHelperText>
                        : null
                      }
                      <ColorPickComponent name={'primary_color_2'} color={theme.primary_color_2} colorPicked={UpdateTheme} />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}  controlId="formGridprimary_color_6">
                      <FormLabel className={classes.label}>{props.t('webdesign:Hover text')}</FormLabel>
                      {theme.primary_color_6 ?
                        <FormHelperText>{theme.primary_color_6}</FormHelperText>
                        : null
                      }
                      <ColorPickComponent name={'primary_color_6'} color={theme.primary_color_6} colorPicked={UpdateTheme} />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}  controlId="formGridprimary_color_7">
                      <FormLabel className={classes.label}>{props.t('webdesign:Category text')}</FormLabel>
                      {theme.primary_color_7 ?
                        <FormHelperText>{theme.primary_color_7}</FormHelperText>
                        : null
                      }
                      <ColorPickComponent name={'primary_color_7'} color={theme.primary_color_7} colorPicked={UpdateTheme} />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <h5 className="font-bold black-text">{props.t('webdesign:Background Color Webpage')}</h5>
                    <Grid item lg={12} md={12} sm={12} xs={12}  controlId="formGridBackgroundColor5">
                      <FormLabel className={classes.label}>{props.t('webdesign:Select a color')}</FormLabel>
                      {theme.background_color_6 ?
                        <FormHelperText>{theme.background_color_6}</FormHelperText>
                        : null
                      }
                      <ColorPickComponent name={'background_color_6'} color={theme.background_color_6} colorPicked={UpdateTheme} />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12}  className="font-bold" controlId="formGridTitle">
                      <h5 className="font-bold black-text">{props.t('webdesign:Navigation, head and pedestals')}

                        <IconButton 
                          onClick={() => setMessages(prevState => ({ ...prevState, messageNavbar: !messages.messageNavbar }))}
                        >
                          < HelpOutlineIcon style={{ color: `black`, backgroundColor: `transparent`, borderColor: `transparent`, fontSize: `smaller` }}/>
                        </IconButton>

                        {messages.messageNavbar ?
                          <PopUpComponent
                            color3={props.color3}
                            backgroundColor2={props.backgroundColor2}
                            border_radius={props.border_radius}
                            text={<Alert
                              variant="info">{props.t('webdesign:Navbar, headers, footers, active route buttons')}
                            </Alert>}
                            content={<div className="overlay-scroll text-align-center">
                              <NavBarDemoComponent
                                {...theme}
                              /></div>}
                            btn2={props.t('Continue')}
                            closePopup={() => closePopup()}
                          />
                          : null}
                      </h5>
                    </Grid>
                  </Grid>
                  <Grid container className="light-border-bottom bottom-card">

                  {formContentBR2.inputs.map((input, key) => (
                    <Grid item lg={12} md={12} sm={12} xs={12} key={key}>
                    <TextField
                      {...register(input.name, { value: theme[input.name] , shouldUnregister: true })}
                      id={input.id}
                      type={input.type}
                      label={props.t(`webdesign:${input.label}`)}
                      autoComplete={input.autocomplete}
                      className="mb-2 webdesign"
                      variant="outlined"
                      fullWidth
                      max={input.max}
                      min={input.min}
                      step={input.step}
                      value={theme[input.id]}
                      onChange={(e) => {
                        const {name, value} = e.target;
                        setTheme(prevState => ({ ...prevState, [name]: value }));
                      }}
                      helperText={
                        errors[input.name]
                          ? props.t(`webdesign:${errors[input.name].message}`)
                          : input.helperText &&
                            props.t(`webdesign:${input.helperText}`)
                      }
                      error={errors[input.name]}
                      required={input.required}
                      >
                      </TextField>
                  </Grid>
                  ))}

                    <Grid item lg={6} md={12} sm={12} xs={12}  controlId="formGridBackgroundColor1">
                      <FormLabel className={classes.label}>{props.t('webdesign:Navigation, head and pedestals')}</FormLabel>
                      {theme.background_color_1 ?
                        <FormHelperText>{theme.background_color_1}</FormHelperText>
                        : null
                      }
                      <ColorPickComponent name={'background_color_1'} color={theme.background_color_1} colorPicked={UpdateTheme} />
                    </Grid>
                    <Grid item lg={6} md={12} sm={12} xs={12} controlId="formGridprimary_color_1">
                      <FormLabel className={classes.label}>{props.t('webdesign:Font Color Navigation')}</FormLabel>
                      {theme.primary_color_1 ?
                        <FormHelperText>{theme.primary_color_1}</FormHelperText>
                        : null
                      }
                      <ColorPickComponent name={'primary_color_1'} color={theme.primary_color_1} colorPicked={UpdateTheme} />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12}  className="font-bold" controlId="formGridTitle">
                      <h5 className="font-bold black-text">{props.t('webdesign:Buttons')}</h5>
                    </Grid>
                  </Grid>
                  <Grid container className="light-border-bottom bottom-card">
                  {formContentBR1.inputs.map((input, key) => (
                    <Grid item lg={12} md={12} sm={12} xs={12} key={key}>
                      <TextField
                        {...register(input.name, { value: theme[input.name], shouldUnregister: true })}
                        id={input.id}
                        type={input.type}
                        label={props.t(`webdesign:${input.label}`)}
                        autoComplete={input.autocomplete}
                        className="mb-2 webdesign"
                        variant="outlined"
                        fullWidth
                        max={input.max}
                        min={input.min}
                        step={input.step}
                        value={theme[input.id]}
                        onChange={(e) => {
                          const {name, value} = e.target;
                          setTheme(prevState => ({ ...prevState, [name]: value }));
                        }}
                        helperText={
                          errors[input.name]
                            ? props.t(`webdesign:${errors[input.name].message}`)
                            : input.helperText &&
                              props.t(`webdesign:${input.helperText}`)
                        }
                        error={errors[input.name]}
                        required={input.required}
                        >
                        </TextField>
                    </Grid>
                  ))}
                    <Grid item lg={6} md={12} sm={12} xs={12}  controlId="formGridprimary_color_4">
                      <FormLabel className={classes.label}>{props.t('webdesign:Font Color Buttons')}</FormLabel>
                      {theme.primary_color_4 ?
                        <FormHelperText>{theme.primary_color_4}</FormHelperText>
                        : null
                      }
                      <ColorPickComponent name={'primary_color_4'} color={theme.primary_color_4} colorPicked={UpdateTheme} />
                    </Grid>
                    <Grid item lg={6} md={12} sm={12} xs={12}  controlId="formGridBackgroundColor2">
                      <FormLabel className={classes.label}>{props.t('webdesign:Background and BorderColor Buttons')}</FormLabel>
                      {theme.background_color_2 ?
                        <FormHelperText>{theme.background_color_2}</FormHelperText>
                        : null
                      }
                      <ColorPickComponent name={'background_color_2'} color={theme.background_color_2} colorPicked={UpdateTheme} />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <h5 className="font-bold black-text">{props.t('webdesign:Photos')}</h5>
                    <Grid item lg={12} md={12} sm={12} xs={12}  controlId="formGridBackgroundColor5">
                      <FormLabel className={classes.label}>{props.t('webdesign:Border Color photo')}</FormLabel>
                      {theme.background_color_5 ?
                        <FormHelperText>{theme.background_color_5}</FormHelperText>
                        : null
                      }
                      <ColorPickComponent name={'background_color_5'} color={theme.background_color_5} colorPicked={UpdateTheme} />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12} className="font-bold" controlId="formGridTitle">

                      <h5 className="font-bold black-text">{props.t('webdesign:Product headers in shoppage')}

                        <IconButton 
                          onClick={() => setMessages(prevState => ({ ...prevState, messageProduct: !messages.messageProduct }))}
                        >
                          < HelpOutlineIcon style={{ color: `black`, backgroundColor: `transparent`, borderColor: `transparent`, fontSize: `smaller` }}/>
                        </IconButton>

                        {messages.messageProduct ?
                          <PopUpComponent
                            primary_color_4={props.primary_color_4}
                            backgroundColor2={props.background_color_2}
                            border_radius={props.border_radius}
                            text={<Alert
                              variant="info">{props.t('webdesign:Left part header products in shoppage')}, {props.t('webdesign:Right part header products in shoppage, borders cart')}
                            </Alert>}
                            content={<div className="text-align-center"><ProductDemoComponent
                              {...theme}
                            /></div>}
                            btn2={props.t('Continue')}
                            closePopup={() => closePopup()}
                          />
                          : null}
                      </h5>
                    </Grid>
                  </Grid>
                  <Grid container className="light-border-bottom bottom-card">
                    <Grid item lg={6} md={12} sm={12} xs={12}  controlId="formGridBackgroundColor3">
                      <FormLabel className={classes.label}>{props.t('webdesign:Left part header products in shoppage')}</FormLabel>
                      {theme.background_color_3 ?
                        <FormHelperText>{theme.background_color_3}</FormHelperText>
                        : null
                      }
                      <ColorPickComponent name={'background_color_3'} color={theme.background_color_3} colorPicked={UpdateTheme} />
                    </Grid>
                    <Grid item lg={6} md={12} sm={12} xs={12}  controlId="formGridBackgroundColor4">
                      <FormLabel className={classes.label}>{props.t('webdesign:Right part header products in shoppage, borders cart')}</FormLabel>
                      {theme.background_color_4 ?
                        <FormHelperText>{theme.background_color_4}</FormHelperText>
                        : null
                      }
                      <ColorPickComponent name={'background_color_4'} color={theme.background_color_4} colorPicked={UpdateTheme} />
                    </Grid>
                  </Grid>
                  <Grid container className="light-border-bottom bottom-card">
                    <Grid item lg={6} md={12} sm={12} xs={12}  controlId="formGridprimary_color_3">
                      <FormLabel className={classes.label}>{props.t('webdesign:Left text header products in shoppage')}</FormLabel>
                      {theme.primary_color_3 ?
                        <FormHelperText>{theme.primary_color_3}</FormHelperText>
                        : null
                      }
                      <ColorPickComponent name={'primary_color_3'} color={theme.primary_color_3} colorPicked={UpdateTheme} />
                    </Grid>
                    <Grid item lg={6} md={12} sm={12} xs={12} controlId="formGridprimary_color_5">
                      <FormLabel className={classes.label}>{props.t('webdesign:Right text header products in shoppage')}</FormLabel>
                      {theme.primary_color_5 ?
                        <FormHelperText>{theme.primary_color_5}</FormHelperText>
                        : null
                      }
                      <ColorPickComponent name={'primary_color_5'} color={theme.primary_color_5} colorPicked={UpdateTheme} />
                    </Grid>
                  </Grid>
                </>
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12} className="" style={{
                backgroundColor: `${theme.background_color_6}`
              }}>
                <h5 className="font-bold bottom-card black-text">{props.t('webdesign:Examples')}</h5>
                <Grid container className="center-alignment bottom-card text-align-center">
                  <NavBarDemoComponent {...theme}  />
                </Grid>
                <Grid container className="center-alignment bottom-card">
                  <FontDemoComponent {...theme} />
                </Grid>
                <Grid container className="center-alignment bottom-card">
                  <HomePhotoDemoComponent {...theme} />
                </Grid>
                <Grid container className="center-alignment bottom-card">
                  <CategoryDemoComponent {...theme} />
                </Grid>
                <Grid container className="center-alignment bottom-card">
                  <ProductDemoComponent {...theme} />
                </Grid>
                <Grid container className="center-alignment bottom-card">

                    <Button
                      style={{
                        fontFamily: `${theme.font_family}`,
                        color: `${theme.primary_color_4}`,
                        backgroundColor: `${theme.background_color_2}`,
                        borderColor: `${theme.background_color_2}`,
                        borderRadius: `${theme.border_radius}px`,
                        width: `-webkit-fill-available`
                      }}
                      variant="contained"
                      className="btn btn-secondary"
                    >
                      <p style={{ fontFamily: `${theme.font_family}`, color: `${theme.primary_color_4}` }} className="modules-button-text">{props.t('webdesign:Example')}</p>
                    </Button>

                </Grid>
              </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} className="font-bold text-align-left">
                  <h4 className="font-bold bottom-card black-text">{props.t('webdesign:Satisfied')}</h4>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="no-padding black-text">
                  <p>Nam condimentum metus ac ipsum aliquet efficitur. Praesent blandit odio eu sem eleifend luctus. Pellentesque eget fringilla libero. Donec ut venenatis augue. Donec nec ligula vel justo imperdiet ornare vitae id nisi. Proin tempor, dui eget efficitur ultricies, risus erat rutrum massa, quis aliquet libero turpis in erat. Quisque nec velit nec odio congue congue sit amet ac justo. Vestibulum odio ipsum, scelerisque ac consectetur a, varius nec massa. Nam eu mattis dolor, iaculis commodo purus.</p>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="bottom-card">

                {formContentCheckbox.inputs.map((input, key) => (
                  <Grid item lg={12} md={12} sm={12} xs={12} key={key}>

                    <FormControl
                      required={input.required}
                      error={errors[input.name]}
                      component="fieldset"
                    >
                      <FormControlLabel
                        {...register(input.name, { shouldUnregister: true })}
                        id={input.id}
                        value={validation.agree}
                        control={
                          <Checkbox
                            color="primary"
                            name={input.name}
                            onChange={(event) =>
                              input.id === "agree" &&
                              setValidation((prevState) => ({
                                ...prevState,
                                agree: event.target.checked,
                              }))
                            }
                          />
                        }
                        label={
                          props.t(`webdesign:${input.label}`)
                        }
                        labelPlacement="end"
                        className="mb-2 webdesign"
                      />
                      {errors[input.name] && (
                        <FormHelperText>
                          <Alert severity="warning">
                            {props.t(`webdesign:${errors[input.name].message}`)}
                          </Alert>
                        </FormHelperText>
                      )}
                    </FormControl>

                  </Grid>
                ))}

                  <p className="font-bold underline black-text">
                    {props.t('webdesign:Help')}
                  </p>
                </Grid>
                <Button
                  type="submit"
                  name="submit"
                  className="nav-button"
                  style={{ backgroundImage: `url(${window.location.origin + "/button_wizard_2_dh@2x.png"})`, backgroundSize: 'cover !important', }}
                >
                  <p className="modules-button-text black-text">{props.t('Continue')}</p>
                </Button>
              </Grid>
          </form>

        </Card.Body>
      </Card>
    </Grid>
  );
}

export default withTranslation(['message', 'webdesign'])(WebDesign);
