import React from 'react';
import ReactDOM from 'react-dom';

import { ApolloProvider } from '@apollo/client';
import { ApolloClient, from, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error';
import 'bootstrap/dist/css/bootstrap.min.css';
import { createUploadLink } from "apollo-upload-client";

import { I18nextProvider } from 'react-i18next';
import i18n from './config/i18n';

import App from './App';
import * as serviceWorker from './serviceWorker';
import './styles/css/style.css';

// Embed Font
// To embed your selected fonts into a webpage, copy this code into the <head> of your HTML document.
// <style>
// @import url('https://fonts.googleapis.com/css?family=Inconsolata|Lato|Merriweather|Montserrat|Open+Sans|Oswald|Playfair+Display|Poppins|Raleway|Roboto&display=swap');
// </style>

// <link href="https://fonts.googleapis.com/css?family=Inconsolata|Lato|Merriweather|Montserrat|Open+Sans|Oswald|Playfair+Display|Poppins|Raleway|Roboto&display=swap" rel="stylesheet"></link>


const GRAPHQL_URL = process.env.REACT_APP_GRAPHQL_URL;

const httpLink = new createUploadLink({
  uri: GRAPHQL_URL
})

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`))
    forward(operation)
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
  }
})

const client = new ApolloClient({
  link: from([errorLink, httpLink]),
  cache: new InMemoryCache()
})

const routing = (
  <I18nextProvider i18n={i18n}>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </I18nextProvider>
)
ReactDOM.render(routing, document.getElementById('root'))


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
