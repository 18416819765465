import React from 'react'
import { Button } from "react-bootstrap";

class Notfound extends React.Component {

  render() {
    return (
      <div className="container page-not-found">
        <h1
          style={{
            fontFamily: `${this.props.font_family}`,
            color: `${this.props.color2}`
          }} >
          This page was not found
        </h1>
        <p>Please get in touch with us, if you are not able to find what your looking for.</p>
        <Button
          href='/contact'
          style={{ color: `${this.props.color3}`, backgroundColor: `${this.props.backgroundColor2}`, borderColor: `${this.props.backgroundColor2}` }}
          className="btn btn-light align-button">
          Contact us
        </Button>
      </div>
    )
  }
}
export default Notfound