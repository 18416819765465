import React from 'react';
import { withTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import { Row, Card } from 'react-bootstrap';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';

class ProductDemoComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      font_family: ''
    };
  }



  render() {

    return (

      <Card className="card bg-light border-dark bottom-card full-width">
        <Row className="no-full-margin-around">
          <Card.Header
            className="card-header border-dark col-8 text-start "
            style={{
              fontFamily: `${this.props.font_family}`,
              backgroundColor: `${this.props.background_color_3}`,
              color: `${this.props.primary_color_3}`,
            }}>
            <Card.Text
              className="font-bold">{this.props.t('product:Product name')}
            </Card.Text>
          </Card.Header>
          <Card.Header
            className="card-header border-dark col-4 text-end"
            style={{
              fontFamily: `${this.props.font_family}`,
              backgroundColor: `${this.props.background_color_4}`,
              color: `${this.props.primary_color_5}`,
            }}>
            <Card.Text
              className="font-bold">€ {this.props.t('product:Product price')}
            </Card.Text>
          </Card.Header>
        </Row>
        <Card.Body className="card-body-and-footer col-12">
          Product
          <img src="https://pixabay.com/get/57e1d14a4c56af14f6d1867dda35367b1c3ddce45259794c_1920.jpg" width="100%" height="auto" alt="" style={{ border: `1px solid ${this.props.background_color_5}` }} />
        </Card.Body>
        <Card.Footer className="card-footer bg-transparent border-dark ADD_TO_CART col-12">
          <Button
            style={{
              fontFamily: `${this.props.font_family}`,
              color: `${this.props.primary_color_4}`,
              backgroundColor: `${this.props.background_color_2}`,
              borderColor: `${this.props.background_color_2}`,
              borderRadius: `${this.props.border_radius}px`,
              width: `-webkit-fill-available`
            }}
            variant="contained"
            className="btn btn-secondary"
            endIcon={<AddShoppingCartIcon color="inherit" />}
          >
            {this.props.t('product:Add')}
          </Button>
        </Card.Footer>
      </Card>

    );
  }
}

export default withTranslation(['message', 'product'])(ProductDemoComponent);
