import React from 'react';
import { Button } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';

class PopUpComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = { showModal: true }
  }
  render() {
    return (
      <Modal show={true} style={{ backgroundColor: `${this.props.backgroundColor2}` }}>
        <Modal.Body>
          <div>{this.props.text}</div>
          {this.props.content ?
            <div>{this.props.content}</div>
            : null}
        </Modal.Body>
        <Modal.Footer>
          {this.props.btn1 ?
            <Button
              style={{ color: `${this.props.primary_color_4}`, backgroundColor: `${this.props.backgroundColor2}`, borderColor: `${this.props.backgroundColor2}` }}
              className="btn btn-light align-around-equal"
            >
              <Link to="">{this.props.btn1}</Link>
            </Button>
            : null}

          <Button
            style={{ color: `${this.props.primary_color_4}`, backgroundColor: `${this.props.backgroundColor2}`, borderColor: `${this.props.backgroundColor2}` }}
            className="btn btn-light align-around-equal"
            onClick={this.props.closePopup}>
            {this.props.btn2}
          </Button>

        </Modal.Footer>
      </Modal>
    );
  }
}

export default PopUpComponent;


